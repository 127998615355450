import React from "react";
import "./polyfills.js";
import { createRoot } from "react-dom/client";
import Root from "./components/Root/Root";
import "config/i18n";
import "./index.css";

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<Root />);
